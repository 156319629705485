<template>
  <div id="register" class="fill-height justify-start pa-0">
    <div class="dxa-form-page-div">
      <div class="outside-information-div">
        <div>
          <p class="dxa-outside-header">
            {{ $t(`banker_onboarding_header`) }}
          </p>
          <p class="dxa-outside-subheader">
            {{ $t(`banker_onboarding_subheader`) }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="!gs.isMobile()">
      <div class="upper-card-div">
        <v-row no-gutters>
          <v-col cols="12" md="8" class="pl-3" style="border-radius: 12px">
            <h3 class="dxa-upper-card-header">
              {{ $t("additional_information") }}
            </h3>
          </v-col>
        </v-row>
      </div>
      <v-slide-y-transition appear>
        <v-card light class="dxa-card" color="white">
          <v-row no-gutters class="dxa-card-row">
            <v-col cols="12" md="8" class="dxa-card-col-8">
              <div class="pa-2 pt-0" style="height: 100%" :class="`text-left`">
                <OnboardingForm :User="user" />
              </div>
            </v-col>
            <v-col cols="0" md="4" class="dxa-card-col-4">
              <img
                alt="DXA"
                src="../../../../../assets/dxa-x-blue.svg"
                class="dxa-upper-img"
              />
              <img
                alt="Icons"
                src="../../../../../assets/dxa-stars-background.png"
                class="dxa-lower-img"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-slide-y-transition>
    </div>
    <div v-else class="px-4">
      <OnboardingForm :User="user" />
    </div>
  </div>
</template>

<style lang="scss">
.crisp-client {
  display: none;
}
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import OnboardingForm from "./Form";
import { mapGetters } from "vuex";
import { bankerRegisterWrongUrl } from "@/shared/helpers/bankerRegisterHelper";

export default {
  name: "BankerOnboarding",
  components: { OnboardingForm },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    valid: false,
    lazy: false,
    loading: false,
    user: {
      Id: null,
      Clients: 0,
      InvestPrivateEquity: false,
      ClientsPrivateEquity: 0,
      InvestmentKnowledge: 0,
    },
    dialog: false,
    error: false,
  }),
  watch: {},
  async created() {
    this.user.Id = this.$route.query.user;
    var partnerUrl = this.$route.query.partner;
    if (partnerUrl) {
      if (!this.configs) {
        await this.apiService
          .getRequest(`partner/get/${partnerUrl}`)
          .then((result) => {
            this.$store.commit("SET_PARTNER", JSON.parse(result));
          })
          .catch((err) => {
            if (err.status === 400) {
              bankerRegisterWrongUrl();
            }
          });
      }
    }
  },
  computed: {
    ...mapGetters({
      configs: "get_partner",
    }),
  },
  methods: {},
};
</script>
<style scoped></style>

<style lang="sass">
#register
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
</style>
