<template>
  <div>
    <DXATextField
      class="my-4"
      :Field="$t('how_many_clients')"
      :Text="User.Clients"
      @text_changed="check_clients"
      :ErrorText="errorClients"
      @update="
        (value) => {
          User.Clients = value;
        }
      "
      Type="number"
      :Min="0"
    />
    <div class="my-4">
      <p id="label" class="mb-1" :style="`color: #9c9c9c;`">
        {{ $t("clients_private") }}
      </p>
      <v-radio-group
        v-model="User.InvestPrivateEquity"
        class="mt-0"
        light
        row
        hide-details
        @change="User.ClientsPrivateEquity = 0"
      >
        <v-radio
          class="dxa-checkbox my-1"
          color="#1A7DFF"
          :label="$t('no')"
          :value="false"
        ></v-radio>
        <v-radio
          class="dxa-checkbox my-1 ml-3"
          color="#1A7DFF"
          :label="$t('yes')"
          :value="true"
        ></v-radio>
      </v-radio-group>
    </div>
    <DXATextField
      class="my-4"
      :Field="$t('how_many_private')"
      :Disabled="!User.InvestPrivateEquity"
      :Text="User.ClientsPrivateEquity"
      @text_changed="check_clients_private_equity"
      :ErrorText="errorClientsPrivateEquity"
      @update="
        (value) => {
          User.ClientsPrivateEquity = value;
        }
      "
      Type="number"
    />
    <div class="my-4">
      <p id="label" class="mb-1" :style="`color: #9c9c9c;`">
        {{ $t("private_knowledge") }}
      </p>
      <v-radio-group v-model="User.InvestmentKnowledge" class="mt-0" light>
        <v-radio
          class="dxa-checkbox my-1"
          color="#1A7DFF"
          v-for="op in knowledge_options"
          :key="op.value"
          :label="op.text"
          :value="op.value"
        ></v-radio>
      </v-radio-group>
    </div>
    <v-row justify="start" class="ma-0 mt-2">
      <v-col cols="12" md="6" class="pa-0">
        <v-btn
          data-test="Banker:Form:FinishInformation"
          @click="check_form"
          :loading="loading"
          class="mx-0 my-1 mb-3 dxa-btn-primary-blue"
          block
          style="height: 45px !important"
          ><span style="font-size: 24px">{{ $t("finish") }}</span></v-btn
        >
      </v-col>
    </v-row>
    <v-alert class="ma-2" v-if="step == 1 && alert" type="error">
      {{ alert }}
    </v-alert>
    <v-dialog v-model="dialog" v-if="dialog" persistent>
      <AlertModal
        @close="reset_modal"
        :IsError="error"
        :Header="dialogHeader"
        :Text="dialogText"
        :User="User"
        :data-test="`Banker:Form:Alert${error ? 'Error' : 'Success'}`"
      />
    </v-dialog>
  </div>
</template>

<style lang="scss">
.crisp-client {
  display: none;
}
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import AlertModal from "@/components/dxa/Modal/AlertModal";
import DXATextField from "@/components/dxa/TextField/DxaTextField";
import { mapGetters } from "vuex";
export default {
  name: "OnboardingForm",
  components: {
    AlertModal,
    DXATextField,
  },
  props: {
    User: Object,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    valid: false,
    lazy: false,
    loading: false,
    alert: null,
    errorClients: null,
    errorClientsPrivateEquity: null,
    User: {
      Id: null,
      Clients: 0,
      InvestPrivateEquity: false,
      ClientsPrivateEquity: 0,
      InvestmentKnowledge: false,
    },
    dialogText: null,
    dialogHeader: null,
    dialog: false,
    error: false,
  }),
  watch: {},
  async created() {},
  computed: {
    errors() {
      return this.errorClients || this.errorClientsPrivateEquity;
    },
    ...mapGetters({
      configs: "get_partner",
    }),
    knowledge_options() {
      return [
        {
          text: this.$t("private_knowlegdge_1"),
          value: 0,
        },
        {
          text: this.$t("private_knowlegdge_2"),
          value: 1,
        },
        {
          text: this.$t("private_knowlegdge_3"),
          value: 2,
        },
        {
          text: this.$t("private_knowlegdge_4"),
          value: 3,
        },
        {
          text: this.$t("private_knowlegdge_5"),
          value: 4,
        },
      ];
    },
  },
  methods: {
    check_clients() {
      this.errorClients = null;
      this.errorClients = this.check_value(this.User.Clients);
      if (!this.errorClients) {
        this.User.Clients = parseInt(this.User.Clients);
      }
    },
    check_clients_private_equity() {
      this.errorClientsPrivateEquity = null;
      this.errorClientsPrivateEquity = this.check_value(
        this.User.ClientsPrivateEquity
      );
      if (!this.errorClientsPrivateEquity) {
        this.User.ClientsPrivateEquity = parseInt(
          this.User.ClientsPrivateEquity
        );
      }
    },
    check_value(value) {
      if (value == null || value === "") {
        return this.$t("required");
      } else if (parseInt(value) < 0) {
        return this.$t("no_negative_field");
      }
      return null;
    },
    error_occoured(error) {
      this.dialogHeader = this.$t(error);
      this.error = true;
      this.dialog = true;
    },
    end_modal() {
      this.dialogHeader = this.$t("welcome_dxa_header");
      this.dialogText = this.$t("welcome_dxa_subheader");
      this.error = false;
      this.dialog = true;
    },
    reset_modal() {
      if (!this.error) {
        this.$router.push("/");
      }
      this.dialog = false;
      this.dialogText = null;
      this.dialogHeader = null;
      this.error = false;
    },
    async check_form() {
      if (this.errors) {
        return;
      }
      this.error = null;
      this.loading = true;
      await this.apiService
        .postRequest("banker/complete-onboarding", this.User)
        .then((result) => {
          var user = JSON.parse(localStorage.getItem("user"));
          user.isOnboarded = true;
          this.$store.commit("SET_LOGGED_USER", JSON.stringify(user));
          this.end_modal();
        })
        .catch((err) => {
          this.error_occoured("error_banker_onboarding");
        });
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.lessThanEight {
  margin-top: 45px;
}
.OnlyEight {
  margin-top: 0px;
}
.lessThan {
  background-color: var(--white);
  min-height: 725px;
}
.eight {
  background-color: rgba(255, 255, 255, 0);
}
</style>

<style lang="sass">
#register
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
</style>
