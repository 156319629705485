<template>
  <div class="dxa-modal text-center pa-2">
    <v-icon
      style="position: absolute; top: 10px; right: 10px"
      @click="$emit('close')"
      color="grey"
      >mdi-close</v-icon
    >
    <div class="px-4 py-6">
      <div v-if="reportSent">
        <h2 class="dxa-modal-header">{{ $t("team_notified_error") }}</h2>
        <p class="dxa-modal-text">{{ $t("team_notified_text") }}</p>
      </div>
      <div v-else-if="!report">
        <h2 class="dxa-modal-header">{{ Header }}</h2>
        <p class="dxa-modal-text">{{ Text }}</p>
        <v-btn
          class="dxa-btn-secondary-blue text-center"
          @click="report = true"
          v-if="IsError"
        >
          {{ $t("contact") }}
        </v-btn>
      </div>
      <div v-else>
        <h2 class="dxa-modal-header">{{ $t("insert_notification") }}</h2>
        <DXATextField
          class="text-left"
          :Field="$t('message')"
          :Text="reportText"
          :FullHeight="true"
          @update="
            (value) => {
              reportText = value;
            }
          "
        />
        <v-row>
          <v-col cols="6">
            <v-btn
              class="dxa-btn-primary-blue"
              :loading="reportLoading"
              @click="submit_error"
              block
            >
              {{ $t("send") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="dxa-btn-secondary-blue" @click="$emit('close')" block>
              {{ $t("cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import DXATextField from "@/components/dxa/TextField/DxaTextField";
import ApiService from "@/services/ApiService";
export default {
  components: { DXATextField },
  data() {
    return {
      apiService: new ApiService(),
      report: false,
      reportText: null,
      reportSent: false,
      reportLoading: false,
    };
  },
  props: {
    Header: String,
    Text: String,
    IsError: Boolean,
    User: Object,
  },
  async created() {},

  methods: {
    async submit_error() {
      this.reportLoading = true;
      var params = {
        UserEmail: this.User.Email,
        UserSocialNumber: this.User.SocialNumber,
        Message: this.reportText,
        Error: this.Header,
      };
      await this.apiService
        .postRequest("errorlog/report", params)
        .then((result) => {
          this.reportSent = true;
        })
        .catch((err) => {});
      this.reportLoading = false;
    },
  },
};
</script>
<style>
.crisp-client {
  display: none;
}
</style>
<style scoped>
.dxa-modal-header {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #1a7dff;
  margin-bottom: 12px !important;
}
.dxa-modal-text {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 12px !important;
}
.dxa-modal {
  background: var(--white);
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  margin: auto;
  max-width: 450px;
}
</style>
