import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dxa-modal text-center pa-2"},[_c(VIcon,{staticStyle:{"position":"absolute","top":"10px","right":"10px"},attrs:{"color":"grey"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-close")]),_c('div',{staticClass:"px-4 py-6"},[(_vm.reportSent)?_c('div',[_c('h2',{staticClass:"dxa-modal-header"},[_vm._v(_vm._s(_vm.$t("team_notified_error")))]),_c('p',{staticClass:"dxa-modal-text"},[_vm._v(_vm._s(_vm.$t("team_notified_text")))])]):(!_vm.report)?_c('div',[_c('h2',{staticClass:"dxa-modal-header"},[_vm._v(_vm._s(_vm.Header))]),_c('p',{staticClass:"dxa-modal-text"},[_vm._v(_vm._s(_vm.Text))]),(_vm.IsError)?_c(VBtn,{staticClass:"dxa-btn-secondary-blue text-center",on:{"click":function($event){_vm.report = true}}},[_vm._v(" "+_vm._s(_vm.$t("contact"))+" ")]):_vm._e()],1):_c('div',[_c('h2',{staticClass:"dxa-modal-header"},[_vm._v(_vm._s(_vm.$t("insert_notification")))]),_c('DXATextField',{staticClass:"text-left",attrs:{"Field":_vm.$t('message'),"Text":_vm.reportText,"FullHeight":true},on:{"update":function (value) {
            _vm.reportText = value;
          }}}),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VBtn,{staticClass:"dxa-btn-primary-blue",attrs:{"loading":_vm.reportLoading,"block":""},on:{"click":_vm.submit_error}},[_vm._v(" "+_vm._s(_vm.$t("send"))+" ")])],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VBtn,{staticClass:"dxa-btn-secondary-blue",attrs:{"block":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }