import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DXATextField',{staticClass:"my-4",attrs:{"Field":_vm.$t('how_many_clients'),"Text":_vm.User.Clients,"ErrorText":_vm.errorClients,"Type":"number","Min":0},on:{"text_changed":_vm.check_clients,"update":function (value) {
        _vm.User.Clients = value;
      }}}),_c('div',{staticClass:"my-4"},[_c('p',{staticClass:"mb-1",style:("color: #9c9c9c;"),attrs:{"id":"label"}},[_vm._v(" "+_vm._s(_vm.$t("clients_private"))+" ")]),_c(VRadioGroup,{staticClass:"mt-0",attrs:{"light":"","row":"","hide-details":""},on:{"change":function($event){_vm.User.ClientsPrivateEquity = 0}},model:{value:(_vm.User.InvestPrivateEquity),callback:function ($$v) {_vm.$set(_vm.User, "InvestPrivateEquity", $$v)},expression:"User.InvestPrivateEquity"}},[_c(VRadio,{staticClass:"dxa-checkbox my-1",attrs:{"color":"#1A7DFF","label":_vm.$t('no'),"value":false}}),_c(VRadio,{staticClass:"dxa-checkbox my-1 ml-3",attrs:{"color":"#1A7DFF","label":_vm.$t('yes'),"value":true}})],1)],1),_c('DXATextField',{staticClass:"my-4",attrs:{"Field":_vm.$t('how_many_private'),"Disabled":!_vm.User.InvestPrivateEquity,"Text":_vm.User.ClientsPrivateEquity,"ErrorText":_vm.errorClientsPrivateEquity,"Type":"number"},on:{"text_changed":_vm.check_clients_private_equity,"update":function (value) {
        _vm.User.ClientsPrivateEquity = value;
      }}}),_c('div',{staticClass:"my-4"},[_c('p',{staticClass:"mb-1",style:("color: #9c9c9c;"),attrs:{"id":"label"}},[_vm._v(" "+_vm._s(_vm.$t("private_knowledge"))+" ")]),_c(VRadioGroup,{staticClass:"mt-0",attrs:{"light":""},model:{value:(_vm.User.InvestmentKnowledge),callback:function ($$v) {_vm.$set(_vm.User, "InvestmentKnowledge", $$v)},expression:"User.InvestmentKnowledge"}},_vm._l((_vm.knowledge_options),function(op){return _c(VRadio,{key:op.value,staticClass:"dxa-checkbox my-1",attrs:{"color":"#1A7DFF","label":op.text,"value":op.value}})}),1)],1),_c(VRow,{staticClass:"ma-0 mt-2",attrs:{"justify":"start"}},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c(VBtn,{staticClass:"mx-0 my-1 mb-3 dxa-btn-primary-blue",staticStyle:{"height":"45px !important"},attrs:{"data-test":"Banker:Form:FinishInformation","loading":_vm.loading,"block":""},on:{"click":_vm.check_form}},[_c('span',{staticStyle:{"font-size":"24px"}},[_vm._v(_vm._s(_vm.$t("finish")))])])],1)],1),(_vm.step == 1 && _vm.alert)?_c(VAlert,{staticClass:"ma-2",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.alert)+" ")]):_vm._e(),(_vm.dialog)?_c(VDialog,{attrs:{"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('AlertModal',{attrs:{"IsError":_vm.error,"Header":_vm.dialogHeader,"Text":_vm.dialogText,"User":_vm.User,"data-test":("Banker:Form:Alert" + (_vm.error ? 'Error' : 'Success'))},on:{"close":_vm.reset_modal}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }